<template>
    <component v-bind:is="getIcon" 
               v-bind="props"></component>
</template>

<script>
import AMEX from '@/assets/images/credit-cards/flat/amex.svg'
import MASTERCARD from '@/assets/images/credit-cards/flat/mastercard.svg'
import VISA from '@/assets/images/credit-cards/flat/visa.svg'

export default{
    components: {
        AMEX,
        MASTERCARD,
        VISA
    },
    props: {
        brand: {
            type: String,
            required: true
        },
        design: {
            type: String,
            default: 'flat'
        },
        height: {},
        width: {},
    },
    mounted(){},
    data()
    {
        return {}
    },
    computed: {
        getIcon()
        {
            /**
             * @todo  handle all icon designs!
             */

            return this.brand.toUpperCase()
        },
        props()
        {
            return {
                height: this.height,
                width: this.width
            }
        }
    },
    methods: {},
    watch: {}
}
</script>